<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full h-full bg-white p-4 md:p-10"
    >
      <div class="w-full h-full flex flex-col">
        <div class="w-full border rounded-3xl p-5 h-full">
          <div class="h-[10%] flex items-center justify-between">
            <p class="font-semibold">History</p>
            <div class="flex flex-row items-center space-x-6">
              <div
                class="w-fit rounded-3xl px-4 py-2 bg-OrangePeel text-white text-xs"
              >
                Avg:
                <span class="font-semibold">{{ average_high }}mg/dl</span>
              </div>
              <div class="flex space-x-4">
                <div class="flex items-center space-x-2">
                  <div class="bg-DodgerBlue w-1 h-1 rounded-full"></div>
                  <span class="text-DodgerBlue text-[10px] lg:text-xs"
                    >Low</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <div class="bg-DarkMint w-1 h-1 rounded-full"></div>
                  <span class="text-DarkMint text-[10px] lg:text-xs"
                    >Normal</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <div class="bg-OrangePeel w-1 h-1 rounded-full"></div>
                  <span class="text-OrangePeel text-[10px] lg:text-xs"
                    >Average High</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <div class="bg-ArtyClickRed w-1 h-1 rounded-full"></div>
                  <span class="text-ArtyClickRed text-[10px] lg:text-xs"
                    >High</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <button
                    class="bg-ResolutionBlue/[0.05] p-2 rounded-md"
                    @click="exportToPDF"
                  >
                    <img
                      src="../../../assets/icons/fi_share.svg"
                      alt="share icon"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="h-[90%]">
            <div class="overflow-x-auto h-full py-6">
              <table
                v-if="getUserBloodGlucoseRecords?.results.length > 0"
                class="w-full"
              >
                <thead>
                  <tr>
                    <th
                      class="min-w-[100px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Glucose Level
                    </th>

                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Meal
                    </th>
                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Date
                    </th>
                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Time
                    </th>
                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Input
                    </th>
                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      glucose_record, index
                    ) in getUserBloodGlucoseRecords?.results"
                    :key="index"
                    class="font-light"
                  >
                    <td
                      class="min-w-[100px] py-5 border-t border-gray-200 text-sm"
                      :class="[
                        glucose_record?.glucose_level < hypoglycaemic
                          ? 'text-DodgerBlue'
                          : glucose_record?.glucose_level >= hypoglycaemic &&
                            glucose_record?.glucose_level <= normal
                          ? 'text-DarkMint'
                          : glucose_record?.glucose_level > normal &&
                            glucose_record?.glucose_level <= average_high
                          ? 'text-OrangePeel'
                          : glucose_record?.glucose_level >= high
                          ? 'text-ArtyClickRed'
                          : '',
                      ]"
                    >
                      {{ glucose_record?.glucose_level }} mg/dl
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{
                        glucose_record?.before_meal === true
                          ? "Before meal"
                          : "After meal"
                      }}
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{ dateFormatter(glucose_record?.check_time) }}
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{ timeFormatter(glucose_record?.check_time) }}
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{ glucose_record?.input }}
                    </td>
                    <td
                      v-if="glucose_record?.input === 'Manual Input'"
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      <button
                        class="border border-ResolutionBlue rounded-2xl text-ResolutionBlue px-4 py-1 font-bold hover:bg-ResolutionBlue hover:text-white"
                        @click="handleEditClick(glucose_record)"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p v-else class="w-full text-center p-4">No vitals</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import { mapGetters } from "@/hooks/mapStore";
import { dateFormatter, timeFormatter } from "@/utils/dateFormatter";
import jsPDF from "jspdf";
import "jspdf-autotable";

const openEditManualTestModal = inject("openEditManualTestModal");

const hypoglycaemic = ref(70);
const normal = ref(100);
const average_high = ref(125);
const high = ref(126);

const { "user/getUserBloodGlucoseRecords": getUserBloodGlucoseRecords } =
  mapGetters();

const postData = computed(() => getUserBloodGlucoseRecords.value);

const paginatedRecords = computed(() => {
  if (postData.value && postData.value.results) {
    const formattedRecords = postData.value.results.map((record) => {
      const formattedDate = dateFormatter(record.check_time);
      const formattedTime = timeFormatter(record.check_time);
      return { ...record, formattedDate, formattedTime };
    });

    return formattedRecords;
  }
  return [];
});

const exportToPDF = () => {
  const records = paginatedRecords.value;

  if (!records || records.length === 0) {
    console.log("No blood glucose data available for export.");
    return;
  }

  const doc = new jsPDF();
  const columns = ["Glucose Level", "Meal", "Date", "Time", "Input"];
  const rows = records.map((record) => [
    `${record.glucose_level} mg/dl`,
    record.before_meal ? "Before meal" : "After meal",
    record.formattedDate,
    record.formattedTime,
    record.input,
  ]);

  doc.text("Blood Glucose History", 14, 15);
  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 20,
    styles: {
      fontSize: 10,
      halign: "left",
    },
  });

  doc.save("blood_glucose_history.pdf");
};

// Export to CSV
const exportToCSV = () => {
  const records = paginatedRecords.value;

  if (!records || records.length === 0) {
    console.log("No blood glucose data available for export.");
    return;
  }

  const csvRows = [];
  const headers = ["Glucose Level", "Meal", "Date", "Time", "Input"];
  csvRows.push(headers.join(","));

  records.forEach((record) => {
    const row = [
      `${record.glucose_level} mg/dl`,
      record.before_meal ? "Before meal" : "After meal",
      record.formattedDate,
      record.formattedTime,
      record.input,
    ];
    csvRows.push(row.join(","));
  });

  const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "blood_glucose_history.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Open the ManualTestModal with the data of the clicked glucose record
const handleEditClick = (glucose_record) => {
  const glucoseLevelId = glucose_record.id; 
  const patientId = glucose_record.patient_id;
  const editingData = {
    glucose_level: glucose_record.glucose_level,
    before_meal: glucose_record.before_meal,
    check_time: glucose_record.check_time,
    input: glucose_record.input,
    units: glucose_record.units,
    created_at: glucose_record.created_at,
    glucose_level_id: glucoseLevelId
  };

  openEditManualTestModal("Manual Test", patientId, editingData); 
};
</script>
