<template>
  <div class="w-full md:w-[70%]">
    <div>
      <!-- General Info -->
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openResidentialAddress !== true,
          'mb-12': openResidentialAddress === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openResidentialAddress = !openResidentialAddress"
        >
          <h4 class="text-sm md:text-base font-bold text-DarkJungleGreen">
            General Info
          </h4>

          <div>
            <span
              ><img
                src="../../../../assets/icons/arrow-up-iocn.svg"
                :class="{
                  'rotate-180': openResidentialAddress !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[500px]': openResidentialAddress === true,
            },
          ]"
        >
          <div class="space-y-6">
            <p class="text-MistBlue font-bold text-xs md:text-sm">
              Primary Care Physician
            </p>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Primary Care Physician's Name
                </p>
                <input
                  v-model="primary_care_physician_name"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.primary_care_physician_name
                      ? getPatientProfile?.person?.primary_care_physician_name
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Hospital
                </p>
                <input
                  v-model="primary_care_physician_hospital"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.primary_care_physician_hospital
                      ? getPatientProfile?.person
                          ?.primary_care_physician_hospital
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Phone Number
                </p>
                <input
                  v-model="primary_care_physician_phone"
                  type="number"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0  px-3 py-2 focus:outline-none  focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.primary_care_physician_phone
                      ? getPatientProfile?.person?.primary_care_physician_phone
                      : '--'
                  "
                />
              </div>

              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Email</p>
                <input
                  v-model="primary_care_physician_email"
                  type="email"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.primary_care_physician_email
                      ? getPatientProfile?.person?.primary_care_physician_email
                      : '--'
                  "
                />
              </div>
            </div>
            <div class="mb-4">
              <h4 class="text-MistBlue font-bold text-xs md:text-sm">
                Preferred Hospital/ Frequently Visited Hospital
              </h4>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div
                  class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                    Hospital Name
                  </p>
                  <input
                    v-model="preferred_hospital"
                    type="text"
                    class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                    :placeholder="
                      getPatientProfile?.person?.preferred_hospital
                        ? getPatientProfile?.person?.preferred_hospital
                        : '--'
                    "
                  />
                </div>
                <div
                  class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                    Country
                  </p>
                  <input
                    v-model="preferred_hospital_country"
                    type="text"
                    class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none  focus:border-ResolutionBlue"
                    :placeholder="
                      getPatientProfile?.person?.preferred_hospital_country
                        ? getPatientProfile?.person?.preferred_hospital_country
                        : '--'
                    "
                  />
                </div>
                <div
                  class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">City</p>
                  <input
                    v-model="preferred_hospital_city"
                    type="text"
                    class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none  focus:border-ResolutionBlue"
                    :placeholder="
                      getPatientProfile?.person?.preferred_hospital_city
                        ? getPatientProfile?.person?.preferred_hospital_city
                        : '--'
                    "
                  />
                </div>
                <div
                  class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">State</p>
                  <input
                    v-model="preferred_hospital_state"
                    type="text"
                    class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 px-3 py-2 focus:outline-none focus:border focus:border-ResolutionBlue"
                    :placeholder="
                      getPatientProfile?.person?.preferred_hospital_state
                        ? getPatientProfile?.person?.preferred_hospital_state
                        : '--'
                    "
                  />
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>

      <!-- Medical History -->
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openMedicalHistory !== true,
          'mb-12': openMedicalHistory === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openMedicalHistory = !openMedicalHistory"
        >
          <h4 class="text-sm md:text-base font-bold text-DarkJungleGreen">
            Medical History
          </h4>
          <div>
            <span
              ><img
                src="../../../../assets/icons/arrow-up-iocn.svg"
                :class="{
                  'rotate-180': openMedicalHistory !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[5000px]': openMedicalHistory === true,
            },
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="w-full px-2">
              <div class="flex flex-col pt-4">
                <label for="height" class="text-[10px] md:text-xs text-OlsoGrey"
                  >Height</label
                >
                <div class="flex items-center gap-2 mt-0.5">
                  <input
                    name="height"
                    id="height"
                    type="number"
                    v-model="height"
                    class="bg-BlueChalk w-9 rounded py-1 px-[10px] text-DavyGrey text-sm text-center font-bold tabular-nums focus:border-[0.5px] outline-[0.5px] outline-ResolutionBlue"
                  />
                  <div class="flex items-center gap-1">
                    <span class="text-DarkJungleGreen text-xs font-semibold">{{
                      height_units
                    }}</span>
                    <div class="flex flex-col gap-1">
                      <button @click="incrementHeight">
                        <img
                          src="../../../../assets/images/number-up-arrow.png"
                          alt="up arrow"
                          class="w-2.5 h-2"
                        />
                      </button>
                      <button @click="decrementHeight">
                        <img
                          src="../../../../assets/images/number-up-arrow.png"
                          alt="down arrow"
                          class="w-2.5 h-2 rotate-180"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="text-right">
                <router-link
                  to="/patient/settings/security-and-preferences?tab=preferences"
                  class="mt-2 text-DarkJungleGreen text-[0.8125rem] font-semibold underline"
                  >Edit S.I unit</router-link
                >
              </div> -->

              <!-- <input
                  v-model="height"
                  type="text"
                  class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.height
                      ? getPatientProfile?.person?.height
                      : '--'
                  "
                /> -->
            </div>

            <div class="w-full px-2">
              <div class="flex flex-col pt-4">
                <label for="weight" class="text-[10px] md:text-xs text-OlsoGrey"
                  >Weight</label
                >
                <div class="flex items-center gap-2 mt-0.5">
                  <input
                    name="weight"
                    id="weight"
                    type="number"
                    v-model="weight"
                    class="bg-BlueChalk w-9 rounded py-1 px-[10px] text-DavyGrey text-sm text-center font-bold tabular-nums focus:border-[0.5px] outline-[0.5px] outline-ResolutionBlue"
                  />
                  <div class="flex items-center gap-1">
                    <span class="text-DarkJungleGreen text-xs font-semibold">{{
                      weight_units
                    }}</span>
                    <div class="flex flex-col gap-1">
                      <button @click="incrementWeight">
                        <img
                          src="../../../../assets/images/number-up-arrow.png"
                          alt="up arrow"
                          class="w-2.5 h-2"
                        />
                      </button>
                      <button @click="decrementWeight">
                        <img
                          src="../../../../assets/images/number-up-arrow.png"
                          alt="down arrow"
                          class="w-2.5 h-2 rotate-180"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="text-right">
                <router-link
                  to="/patient/settings/security-and-preferences?tab=preferences"
                  class="mt-2 text-DarkJungleGreen text-[0.8125rem] font-semibold underline"
                  >Edit S.I unit</router-link
                >
              </div> -->
              <!-- <input
                  v-model="weight"
                  type="text"
                  class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.weight
                      ? getPatientProfile?.person?.weight
                      : '--'
                  "
                /> -->
            </div>

            <div
              class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Blood group
              </p>
              <select
                name=""
                id=""
                class="border-transparent pl-1 pr-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue cursor-pointer"
                v-model="blood_group"
              >
                <option value="" disabled selected>
                  {{
                    getPatientProfile?.person?.blood_group
                      ? getPatientProfile?.person?.blood_group
                      : "--"
                  }}
                </option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="AB">AB</option>
                <option value="O">O</option>
              </select>
            </div>
            <div
              class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Diabetes type
              </p>
              <select
                name=""
                id=""
                class="border-transparent pl-1 pr-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue cursor-pointer"
                v-model="diabetes_type"
              >
                <option value="" disabled selected>
                  {{
                    getPatientProfile?.person?.diabetes_type
                      ? getPatientProfile?.person?.diabetes_type
                      : "--"
                  }}
                </option>
                <option value="Type 1">Type 1</option>
                <option value="Type 2">Type 2</option>
              </select>
            </div>

            <InputField
              label="Date of Diabetes Diagnosis"
              v-model="date_of_diagnosis"
              type="date"
              :placeholder="
                getPatientProfile?.person?.date_of_diagnosis
                  ? getPatientProfile?.person?.date_of_diagnosis
                  : '--'
              "
              :max="currentDate"
            />

            <div
              class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Insulin Dosage (if applicable)
              </p>
              <input
                v-model="insulin_dosage"
                type="text"
                class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                :placeholder="
                  getPatientProfile?.person?.insulin_dosage
                    ? getPatientProfile?.person?.insulin_dosage
                    : '--'
                "
              />
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Chronic Diseases
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 1
                </p>
                <input
                  v-model="chronic_disease_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.chronic_disease_1
                      ? getPatientProfile?.person?.chronic_disease_1
                      : '--'
                  "
                />
              </div>

              <InputField
                label="Date of Diagnosis"
                v-model="date_of_chronic_1_diagnosis"
                type="date"
                :placeholder="
                  getPatientProfile?.person?.date_of_chronic_1_diagnosis
                    ? getPatientProfile?.person?.date_of_chronic_1_diagnosis
                    : '--'
                "
                :max="currentDate"
              />

              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <input
                  v-model="chronic_disease_1_medication"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.chronic_disease_1_medication
                      ? getPatientProfile?.person?.chronic_disease_1_medication
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 2
                </p>
                <input
                  v-model="chronic_disease_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.chronic_disease_2
                      ? getPatientProfile?.person?.chronic_disease_2
                      : '--'
                  "
                />
              </div>

              <InputField
                label="Date of Diagnosis"
                v-model="date_of_chronic_2_diagnosis"
                type="date"
                :placeholder="
                  getPatientProfile?.person?.date_of_chronic_2_diagnosis
                    ? getPatientProfile?.person?.date_of_chronic_2_diagnosis
                    : '--'
                "
                :max="currentDate"
              />

              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <input
                  v-model="chronic_disease_2_medication"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.chronic_disease_2_medication
                      ? getPatientProfile?.person?.chronic_disease_2_medication
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 3
                </p>
                <input
                  v-model="chronic_disease_3"
                  type="number"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.chronic_disease_3
                      ? getPatientProfile?.person?.chronic_disease_3
                      : '--'
                  "
                />
              </div>

              <InputField
                label="Date of Diagnosis"
                v-model="date_of_chronic_3_diagnosis"
                type="date"
                :placeholder="
                  getPatientProfile?.person?.date_of_chronic_3_diagnosis
                    ? getPatientProfile?.person?.date_of_chronic_3_diagnosis
                    : '--'
                "
                :max="currentDate"
              />

              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <input
                  v-model="chronic_disease_3_medication"
                  type="text"
                  class="border-transparent  text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none  focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.chronic_disease_3_medication
                      ? getPatientProfile?.person?.chronic_disease_3_medication
                      : '--'
                  "
                />
              </div>
              <!-- <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 4
                </p>
                <input
                v-model=""
                  type="number"
                  class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.primary_care_physician_email
                      ? getPatientProfile?.person?.primary_care_physician_email
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Date of Diagnosis
                </p>
                <input
                v-model=""
                  type="date"
                  class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.primary_care_physician_email
                      ? getPatientProfile?.person?.primary_care_physician_email
                      : '--'
                  "
                />
              </div>
              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <input
                v-model=""
                  type="text"
                  class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.primary_care_physician_email
                      ? getPatientProfile?.person?.primary_care_physician_email
                      : '--'
                  "
                />
              </div> -->
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Surgical History
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Surgery 1
                </p>
                <input
                  v-model="surgery_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.surgery_1
                      ? getPatientProfile?.person?.surgery_1
                      : '--'
                  "
                />
              </div>

              <InputField
                label="Date of Surgery"
                v-model="date_of_surgery_1"
                type="date"
                :placeholder="
                  getPatientProfile?.person?.date_of_surgery_1
                    ? getPatientProfile?.person?.date_of_surgery_1
                    : '--'
                "
                :max="currentDate"
              />

              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Reason for Surgery
                </p>
                <input
                  v-model="reason_for_surgery_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.reason_for_surgery_1
                      ? getPatientProfile?.person?.reason_for_surgery_1
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Surgery 2
                </p>
                <input
                  v-model="surgery_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.surgery_2
                      ? getPatientProfile?.person?.surgery_2
                      : '--'
                  "
                />
              </div>

              <InputField
                label="Date of Surgery"
                v-model="date_of_surgery_2"
                type="date"
                :placeholder="
                  getPatientProfile?.person?.date_of_surgery_2
                    ? getPatientProfile?.person?.date_of_surgery_2
                    : '--'
                "
                :max="currentDate"
              />

              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Reason for Surgery
                </p>
                <input
                  v-model="reason_for_surgery_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.reason_for_surgery_2
                      ? getPatientProfile?.person?.reason_for_surgery_2
                      : '--'
                  "
                />
              </div>
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Allergies
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 1
                </p>
                <input
                  v-model="allergy_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.allergy_1
                      ? getPatientProfile?.person?.allergy_1
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 2
                </p>
                <input
                  v-model="allergy_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none  focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.allergy_2
                      ? getPatientProfile?.person?.allergy_2
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 3
                </p>
                <input
                  v-model="allergy_3"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.allergy_3
                      ? getPatientProfile?.person?.allergy_3
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 4
                </p>
                <input
                  v-model="allergy_4"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none  focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.allergy_4
                      ? getPatientProfile?.person?.allergy_4
                      : '--'
                  "
                />
              </div>
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Other Medications (excluding diabetic drugs)
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 1
                </p>
                <input
                  v-model="medication_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.medication_1
                      ? getPatientProfile?.person?.medication_1
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <input
                  v-model="dosage_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.dosage_1
                      ? getPatientProfile?.person?.dosage_1
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 2
                </p>
                <input
                  v-model="medication_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.medication_2
                      ? getPatientProfile?.person?.medication_2
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <input
                  v-model="dosage_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none  focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.dosage_2
                      ? getPatientProfile?.person?.dosage_2
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 3
                </p>
                <input
                  v-model="medication_3"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.medication_3
                      ? getPatientProfile?.person?.medication_3
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <input
                  v-model="dosage_3"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.dosage_3
                      ? getPatientProfile?.person?.dosage_3
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 4
                </p>
                <input
                  v-model="medication_4"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.medication_4
                      ? getPatientProfile?.person?.medication_4
                      : '--'
                  "
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <input
                  v-model="dosage_4"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="
                    getPatientProfile?.person?.dosage_4
                      ? getPatientProfile?.person?.dosage_4
                      : '--'
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="update-profile-btn-wrapper">
      <button
        @click="updateMedicalInfo"
        type="submit"
        class="w-48 px-8 py-3 bg-ResolutionBlue text-white rounded-3xl hover:bg-DodgerBlue hover:text-white transition-all duration-300"
      >
        <span v-if="isLoading">
          <LoadingSpinner />
        </span>
        <span v-else>Update profile</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import InputField from "../../ui/InputField.vue";

const {
  "user/fetchPatientProfile": fetchPatientProfile,
  "user/updateUserMedicalInfo": updateUserMedicalInfo,
} = mapActions();

const { "user/getPatientProfile": getPatientProfile } = mapGetters();

const openResidentialAddress = ref(true);
const openMedicalHistory = ref(true);
const isLoading = ref(false);

const weight = ref(0);
const height = ref(0);
const weight_units = ref("kg");
const height_units = ref("ft");
const blood_group = ref("");
const diabetes_type = ref("");
const date_of_diagnosis = ref("");
const insulin_dosage = ref("");
const chronic_disease_1 = ref("");
const date_of_chronic_1_diagnosis = ref("");
const chronic_disease_1_medication = ref("");
const chronic_disease_2 = ref("");
const date_of_chronic_2_diagnosis = ref("");
const chronic_disease_2_medication = ref("");
const chronic_disease_3 = ref("");
const date_of_chronic_3_diagnosis = ref("");
const chronic_disease_3_medication = ref("");
const surgery_1 = ref("");
const date_of_surgery_1 = ref("");
const reason_for_surgery_1 = ref("");
const surgery_2 = ref("");
const date_of_surgery_2 = ref("");
const reason_for_surgery_2 = ref("");
const allergy_1 = ref("");
const allergy_2 = ref("");
const allergy_3 = ref("");
const allergy_4 = ref("");
const medication_1 = ref("");
const dosage_1 = ref("");
const medication_2 = ref("");
const dosage_2 = ref("");
const medication_3 = ref("");
const dosage_3 = ref("");
const medication_4 = ref("");
const dosage_4 = ref("");
const primary_care_physician_name = ref("");
const primary_care_physician_hospital = ref("");
const primary_care_physician_phone = ref("");
const primary_care_physician_email = ref("");
const preferred_hospital = ref("");
const preferred_hospital_city = ref("");
const preferred_hospital_state = ref("");
const preferred_hospital_zip_code = ref("");
const preferred_hospital_street = ref("");
const preferred_hospital_country = ref("");
const preferred_hospital_phone = ref("");

const currentDate = ref(new Date().toISOString().slice(0, 10));

// const second_state = ref("");
// const second_street = ref("");
// const second_zip_code = ref("");
// const secondary_email = ref("");
// const secondary_phone = ref("");
// const state = ref("");
// const state_of_origin = ref("");
// const street = ref("");

const incrementHeight = () => height.value++;
const decrementHeight = () => {
  if (height.value > 0) {
    height.value--;
  }
};

const incrementWeight = () => weight.value++;
const decrementWeight = () => {
  if (weight.value > 0) {
    weight.value--;
  }
};

const updateMedicalInfo = async () => {
  const userUpdateCredentials = {
    weight: weight.value,
    height: height.value,
    blood_group: blood_group.value,
    diabetes_type: diabetes_type.value,
    date_of_diagnosis: date_of_diagnosis.value,
    insulin_dosage: insulin_dosage.value,
    chronic_disease_1: chronic_disease_1.value,
    date_of_chronic_1_diagnosis: date_of_chronic_1_diagnosis.value,
    chronic_disease_1_medication: chronic_disease_1_medication.value,
    chronic_disease_2: chronic_disease_2.value,
    date_of_chronic_2_diagnosis: date_of_chronic_2_diagnosis.value,
    chronic_disease_2_medication: chronic_disease_2_medication.value,
    chronic_disease_3: chronic_disease_3.value,
    date_of_chronic_3_diagnosis: date_of_chronic_3_diagnosis.value,
    chronic_disease_3_medication: chronic_disease_3_medication.value,
    surgery_1: surgery_1.value,
    date_of_surgery_1: date_of_surgery_1.value,
    reason_for_surgery_1: reason_for_surgery_1.value,
    surgery_2: surgery_2.value,
    date_of_surgery_2: date_of_surgery_2.value,
    reason_for_surgery_2: reason_for_surgery_2.value,
    allergy_1: allergy_1.value,
    allergy_2: allergy_2.value,
    allergy_3: allergy_3.value,
    allergy_4: allergy_4.value,
    medication_1: medication_1.value,
    dosage_1: dosage_1.value,
    medication_2: medication_2.value,
    dosage_2: dosage_2.value,
    medication_3: medication_3.value,
    dosage_3: dosage_3.value,
    medication_4: medication_4.value,
    dosage_4: dosage_4.value,
    primary_care_physician_name: primary_care_physician_name.value,
    primary_care_physician_phone: primary_care_physician_phone.value,
    primary_care_physician_email: primary_care_physician_email.value,
    primary_care_physician_hospital: primary_care_physician_hospital.value,
    preferred_hospital: preferred_hospital.value,
    preferred_hospital_street: preferred_hospital_street.value,
    // preferred_hospital_city: preferred_hospital_city.value,
    preferred_hospital_state: preferred_hospital_state.value,
    preferred_hospital_zip_code: preferred_hospital_zip_code.value,
    preferred_hospital_country: preferred_hospital_country.value,
    preferred_hospital_phone: preferred_hospital_phone.value,

    // second_state: second_state.value,
    // second_street: second_street.value,
    // second_zip_code: second_zip_code.value,
    // secondary_email: secondary_email.value,
    // secondary_phone: secondary_phone.value,
    // state: state.value,
    // state_of_origin: state_of_origin.value,
    // street: street.value,
  };
  try {
    isLoading.value = true;
    const response = await updateUserMedicalInfo(userUpdateCredentials);
    console.log("response medical cc", response);
    if (response.message === "User details updated successfully") {
      console.log("update response bb", response);
      toast.success("Medical details updated successfully", {
        autoClose: 3000,
      });
      //
    }
  } catch (error) {
    console.error("update medical error", error);
    toast.error("Error updating medical profile", {
      autoClose: 3000,
    });
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await fetchPatientProfile();
  weight.value = getPatientProfile.value.person?.weight ?? 0;
  height.value = getPatientProfile.value.person?.height ?? 0;
  weight_units.value = getPatientProfile.value.person?.weight_units ?? "kg";
  height_units.value = getPatientProfile.value.person?.height_units ?? "cm";
});
</script>
