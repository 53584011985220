import axios from "@/api";

const state = {
  allPatientRequests: [],
  totalPages: 1,
  patientDetails: {},
};

const getters = {
  getPatientRequests: (state) => {
    return state.allPatientRequests;
  },
  totalPages: (state) => state.totalPages,
  patientDetails: (state) => state.patientDetails,
};

const actions = {
  async fetchPatientRequests({ commit }, page = 1) {
    try {
      const response = await axios.get("/data/patient/connections/list", {
        params: {
          page: page
        }
      });
      commit("setPatientRequests", response.data);
      commit("setTotalPages", response.data.total_pages);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async sendPatientInvite(_, payload) {
    try {
      const response = await axios.post(
        `/data/patient/connection/request/${payload.id}`,
        {
          relationship: payload.relationship,
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  },

  async acceptPatientRequest(_, payload) {
    try {
      const response = await axios.put(
        `/data/patient/connection/response/${payload.id}`,
        {
          response: payload.response,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async unlinkPatientAccount(_, payload) {
    try {
      const response = await axios.delete(
        `/data/patient/connection/cancel/${payload.id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async cancelSentRequest(_, payload) {
    try {
      const response = await axios.delete(
        `/data/patient/connection/cancel/${payload.id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getLinkedAccountVitals({ commit }, payload) {
    try {
      const response = await axios.get(
        `/data/patient/glucose_level_list/connected/${payload.id}`, {
        }
      );
      commit("setPatientDetails", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getLinkedCenterInfo(_, payload) {
    try {
      const response = await axios.get(
        `/data/patient/health_centre/${payload.id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  setPatientRequests(state, payload) {
    state.allPatientRequests = payload;
  },
  setTotalPages(state, pages) {
    state.totalPages = pages;
  },
  setPatientDetails(state, details) {
    state.patientDetails = details;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
