<template>
  <section
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="bg-white w-full xl:mx-10 rounded-lg shadow-xl p-4 md:p-10">
      <h1 class="text-ResolutionBlue text-2xl font-bold mb-6">
        Diagnostic Imaging Hub
      </h1>

      <div class="w-full h-full border border-BlueChalk rounded-[18px] py-6 px-10">
        <div class="flex justify-between items-center mb-6 flex-wrap gap-y-2">
          <h3 class="text-BalticSea text-base font-bold">Documents</h3>

          <div class="flex items-center gap-3 flex-wrap">
            <!-- <div class="flex items-center gap-4 flex-wrap text-xs md:text-sm">
                  <div class="flex flex-col gap-1">
                    <label for="fromDate" class="text-BluishGrey font-medium"
                      >Select From Date</label
                    >
                    <input
                      type="date"
                      id="fromDate"
                      class="outline-none"
                      v-model="fromDate"
                    />
                  </div>

                  <div class="flex flex-col gap-1">
                    <label for="toDate" class="text-BluishGrey font-medium"
                      >Select To Date</label
                    >
                    <input
                      type="date"
                      id="toDate"
                      class="outline-none"
                      v-model="toDate"
                    />
                  </div>
                </div> -->

            <button
              v-if="!isAdmin"
              class="bg-ResolutionBlue text-white font-semibold rounded-[40px] py-1.5 px-3 flex justify-between items-center gap-1"
              :class="isLoading && 'opacity-50'"
              :disabled="isLoading"
              @click="openReportAndStatisticsModal"
            >
              <img
                src="../../../assets/icons/add_circle_white.svg"
                alt="add circle icon"
              />
              <span class="text-xs">Upload</span>
            </button>
          </div>
        </div>

        <div
          v-if="isLoading"
          class="w-full h-64 flex justify-center items-center"
        >
          <img
            src="../../../assets/icons/loading-01.svg"
            alt="loading icon"
            class="animate-spin"
          />
        </div>

        <div
          v-else-if="!isLoading && allDocuments.length > 0"
          class="overflow-x-scroll custom-scrollbar"
        >
          <table class="w-full min-w-[800px] text-left">
            <thead>
              <tr
                class="border-b border-Platinum text-MistBlue text-sm font-bold"
              >
                <th class="pb-[18px]">S/N</th>
                <th class="pb-[18px]">Name</th>
                <th class="pb-[18px]">Type</th>
                <th class="pb-[18px]">Notes</th>
                <th class="pb-[18px]">Upload Date</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(document, index) in paginatedDocument"
                :key="document.id"
                class="w-full border-b border-Platinum text-MistBlue text-sm"
              >
                <td class="py-[18px]">{{ (currentPage - 1) * 10 + (index + 1) }}</td>

                <td class="py-[18px]">
                  {{ document.uploader }}
                </td>

                <td class="py-[18px]">
                  {{ document.procedure }}
                </td>

                <td class="py-[18px]">
                  {{ document.note.slice(0, 20) || "-----"
                  }}{{ document.note.length > 20 ? "..." : "" }}
                </td>

                <td class="py-[18px]">
                  {{ dateFormatter(document.created_at) }}
                </td>

                <td class="py-[18px]">
                  <v-menu open-on-hover>
                    <template v-slot:activator="{ props }">
                      <button
                        v-bind="props"
                        class="hover:bg-WhiteLilac p-2 text-2xl md:text-3xl font-bold rounded flex justify-center items-center transition-colors duration-300"
                      >
                        <img :src="optionButton" alt="three dots" />
                      </button>
                    </template>

                    <ReportActionBox
                      @close="closeActionBox"
                      @delete="deleteReport(document.id)"
                      :pdf="document.documents"
                    />
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>  
        </div>
      

        <div
          v-else
          class="w-full h-64 flex flex-col justify-center items-center gap-5"
        >
          <div class="w-14 h-14 md:w-20 md:h-20">
            <img :src="documentImg" alt="document icon" />
          </div>

          <p class="text-sm md:text-base font-semibold text-center">
            You have no health report in your Document Hub, click on upload to
            add a new report
          </p>
        </div>
        <div class="flex items-center py-4">
            <button
              class="flex justify-center items-center w-[38px] h-[38px] bg-gray-100 border rounded-[4px] disabled:opacity-50"
              :disabled="currentPage === 1"
              @click="currentPage--"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>

            <div class="flex space-x-2 mx-2">
              <button
                v-for="page in visiblePages"
                :key="page"
                @click="currentPage = page"
                :class="[
                  'w-[38px] h-[38px] flex justify-center items-center border rounded-[4px]',
                  currentPage === page
                    ? 'bg-ResolutionBlue text-white'
                    : 'bg-gray-100 text-black',
                ]"
              >
                {{ page }}
              </button>
            </div>
            <button
              class="flex justify-center items-center w-[38px] h-[38px] bg-gray-100 border rounded-[4px] disabled:opacity-50"
              :disabled="currentPage === totalPages"
              @click="currentPage++"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>

            <span class="text-gray-500 ml-2">of {{ totalPages }} pages</span>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import { inject, ref, computed, watch } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import { dateFormatter } from "@/utils/dateFormatter";
import debounce from "lodash/debounce";
import ReportActionBox from "@/components/main/patient/reportAndStatistics/ReportActionBox.vue";
import optionButton from "@/assets/icons/option-button.svg";
import documentImg from "@/assets/images/document.png";

export default {
  name: "ReportAndStatistics",
  components: {
    ReportActionBox,
  },
  setup() {
    const {
      "user/getAllDocumentUploads": getAllDocumentUploads,
      "user/deleteDocument": deleteDocument,
    } = mapActions();

    const {
      "auth/getIsAdmin": isAdmin,
      "user/getAllDocumentUploads": allDocuments,
    } = mapGetters();

    const openReportAndStatisticsModal = inject("openReportAndStatisticsModal");
    const openReportNoteModal = inject("openReportNoteModal");
    const reportNote = inject("reportNote");

        const setCurrentNote = (val) => {
      reportNote.value = val;
      openReportNoteModal();
        };

            const closeActionBox = () => {
      currentIndex.value = null;
    };

    const store = useStore();
    const isLoading = ref(false);
    const currentPage = ref(1);

    const totalPages = computed(() => store.getters['user/totalPages']);

    const getAllDocuments = async (page = 1) => {
      isLoading.value = true;
      try {
        await getAllDocumentUploads({ page });
      } catch (error) {
        console.log("err", error);
      } finally {
        isLoading.value = false;
      }
    };

    // Initial fetch
    getAllDocuments();

        // const downloadFilteredDoc = async () => {
    //   if (fromDate.value && toDate.value) {
    //   }
    // };

        const deleteReport = async (id) => {
      try {
        const res = await deleteDocument(id);
        toast.success(res, {
          autoClose: 2000,
        });
        getAllDocuments();
      } catch (error) {
        toast.error("Error deleting document", {
          autoClose: 2000,
        });
      }
    };

    // Debounced function to avoid too many API calls
    const debouncedGetDocuments = debounce(async (page) => {
      await getAllDocuments(page);
    }, 500); // Adjust debounce time as necessary

    watch(currentPage, (newPage) => {
      debouncedGetDocuments(newPage);
    });

    const visiblePages = computed(() => {
      const maxVisible = 5;
      let startPage = Math.max(1, currentPage.value - Math.floor(maxVisible / 2));
      let endPage = Math.min(totalPages.value, startPage + maxVisible - 1);
      if (endPage - startPage + 1 < maxVisible && totalPages.value >= maxVisible) {
        startPage = Math.max(1, endPage - maxVisible + 1);
      }
      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    });

    const paginatedDocument = computed(() => allDocuments.value);

    return {
      optionButton,
      documentImg,
      isAdmin,
      allDocuments,
      dateFormatter,
      isLoading,
      currentPage,
      totalPages,
      paginatedDocument,
      visiblePages,
      openReportAndStatisticsModal,
      setCurrentNote,
      deleteReport,
      closeActionBox
    };
  },
};

</script>
