<template>
  <div>
    <slot></slot>

    <AppointmentModal
      v-show="showAppointmentModal"
      :appointmentValues="appointmentModalVal"
    />

    <LinkedAppointmentModal
      v-show="showLinkedAppointmentModal"
      :appointmentValues="appointmentModalVal"
    />

    <ReportAndStatisticsModal v-show="showReportAndStatisticsModal" />
    <ReportNoteModal v-show="showReportNoteModal" :note="reportNote" />
    <FileUploadSuccessfulModal v-show="showFileUploadSuccessfulModal" />

    <UnlinkPatientModal
      v-show="showUnlinkPatientModal"
      :account="selectedAccount"
    />

    <SelectTestTypeModal v-show="showSelectTestTypeModal" />
    <SearchPatientModal v-show="showSearchPatientModal" />
    <TestCenterModal v-if="showTestModal" />
    <AddNewPatientModal v-show="showAddNewPatientModal" />
    <TestStepsModal v-show="showTestStepsModal" />
    <TestSuccessfulModal v-show="showTestSuccessfulModal" />

    <TakeTestModal v-show="showTakeTestModal" />
    <!-- Test Instruction Modal -->
    <TestInstructionModal
      v-show="showTestInstructionModal"
      :selectedDeviceImage="selectedDeviceImage"
      :selectedTestType="selectedTestType"
      :isPatientDevice="isPatientDevice"
    />

    <ManualTestModal
      v-show="showManualTestModal"
      :selectedTestType="manualTestModalData.selectedTestType"
      :patientId="manualTestModalData.patientId"
      :existingData="manualTestModalData.existingData"
      :editingData="manualTestModalData.editingData"
    />

    <EditManualTestModal
      v-show="showEditManualTestModal"
      :patientName="editManualTestModalData.patientName"
      :patientId="editManualTestModalData.patientId"
      :editingData="editManualTestModalData.editingData"
    />

    <LinkedAccountTakeTestModal
      v-show="showLinkedAccountTakeTestModal"
      :patientName="linkedAccountPatientName"
      :patientId="linkedAccountPatientId"
    />

    <LinkedAccountManualTestModal
      v-show="showLinkedAccountManualTestModal"
      :patientId="linkedAccountPatientId"
      :patientName="linkedAccountPatientName"
      :selectedTestType="'Manual Test'"
      :existingData="manualTestModalData.existingData"
    />

    <MealSelectModal
      v-show="showMealSelectModal"
      :glucoseLevelId="mealSelectModalData.glucoseLevelId"
      @close="closeMealSelectModal"
    />

    <ViewBlogPost v-if="showBlogPostModal" :post="selectedPost" />
    <EditBlogPost v-if="showEditBlogModal" :post="selectedPost" />
    <DeleteBlogPost v-if="showDeleteBlogModal" :post="selectedPost" />
  </div>
</template>

<script setup>
import { ref, provide } from "vue";
import AppointmentModal from "./patient/appointment/AppointmentModal.vue";
import ReportAndStatisticsModal from "./patient/reportAndStatistics/ReportAndStatisticsModal.vue";
import ReportNoteModal from "./patient/reportAndStatistics/ReportNoteModal.vue";
import UnlinkPatientModal from "./patient/UnlinkPatient/UnlinkPatient.vue";
import FileUploadSuccessfulModal from "./patient/reportAndStatistics/FileUploadSuccessfulModal.vue";
import SelectTestTypeModal from "./admin/testCenter/modals/SelectTestTypeModal.vue";
import SearchPatientModal from "./admin/testCenter/modals/SearchPatientModal.vue";
import TestCenterModal from "./admin/testCenter/modals/TestCenterModal.vue";
import AddNewPatientModal from "./admin/testCenter/modals/AddNewPatientModal.vue";
import TestStepsModal from "./admin/testCenter/modals/TestStepsModal.vue";
import TestSuccessfulModal from "./admin/testCenter/modals/TestSuccessfulModal.vue";
import LinkedAppointmentModal from "./patient/appointment/LinkedAppointmentModal.vue";
import TakeTestModal from "./admin/testCenter/modals/TakeTestModal.vue";
import TestInstructionModal from "./admin/testCenter/modals/TestInstructionModal.vue";
import ManualTestModal from "./admin/testCenter/modals/ManualTestModal.vue";
import LinkedAccountTakeTestModal from "./admin/testCenter/modals/LinkedAccountTakeTestModal.vue";
import LinkedAccountManualTestModal from "./admin/testCenter/modals/LinkedAccountManualTestModal.vue";
import MealSelectModal from "./admin/testCenter/modals/MealSelectModal.vue";
import EditManualTestModal from "./admin/testCenter/modals/EditManualTestModal.vue";

import ViewBlogPost from "./admin/blog/ViewBlogPost.vue";
import EditBlogPost from "./admin/blog/EditBlogPost.vue";
import DeleteBlogPost from "./admin/blog/DeleteBlogPost.vue";

// Appointment modal
const showAppointmentModal = ref(false);
const showLinkedAppointmentModal = ref(false);
const appointmentModalVal = ref({
  type: "default",
  country: "",
  state: "",
  city: "",
  center: "",
  appointmentReason: "",
  address: "",
  date: "",
  time: "",
  appointmentId: "",
});

// Report and statistics modal
const showReportAndStatisticsModal = ref(false);

// Report note modal
const showReportNoteModal = ref(false);
const reportNote = ref("");

// File upload successful modal
const showFileUploadSuccessfulModal = ref(false);

//unlink patient modal
const showUnlinkPatientModal = ref(false);
const selectedAccount = ref(null);

// Test center modal
const selectedPatient = ref(null);
const showSelectTestTypeModal = ref(false);
const showSearchPatientModal = ref(false);
const showTestModal = ref(false);
const showAddNewPatientModal = ref(false);
const showTestStepsModal = ref(false);
const showTestSuccessfulModal = ref(false);
const showTakeTestModal = ref(false);
const showTestInstructionModal = ref(false);
const currentPatientAndDevice = ref({
  patient: null,
  device: null,
});
const selectedDeviceImage = ref(null);
const selectedTestType = ref("");
const showManualTestModal = ref(false);
const showEditManualTestModal = ref(false);
const editManualTestModalData = ref({});
const selectedTestTypeForManualTest = ref("");

const showLinkedAccountTakeTestModal = ref(false);
const showLinkedAccountManualTestModal = ref(false);
const linkedAccountPatientName = ref("");
const linkedAccountPatientId = ref("");

const showMealSelectModal = ref(false);
const mealSelectModalData = ref({});
const isPatientDevice = ref(false);
const selectedDeviceView = ref(null);
const selectedDeviceId = ref(null);
const showDeviceSelection = ref(false);
const showDeviceOptions = ref(false);

// Function to open the LinkedAccountTakeTestModal
const openLinkedAccountTakeTestModal = (patientName, patientId) => {
  linkedAccountPatientName.value = patientName;
  linkedAccountPatientId.value = patientId;
  showLinkedAccountTakeTestModal.value = true;
};

// Function to close the LinkedAccountTakeTestModal
const closeLinkedAccountTakeTestModal = () => {
  // Clear linked account test modal data
  linkedAccountPatientName.value = "";
  linkedAccountPatientId.value = "";

  // Close the modal
  showLinkedAccountTakeTestModal.value = false;
};

// Function to open the LinkedAccountManualTestModal
const openLinkedAccountManualTestModal = (
  patientId,
  patientName,
  existingData
) => {
  linkedAccountPatientId.value = patientId;
  linkedAccountPatientName.value = patientName;

  // Ensure you pass the existingData here
  manualTestModalData.value = {
    selectedTestType: "Manual Test",
    patientId: patientId,
    existingData: existingData || {},
  };

  showLinkedAccountManualTestModal.value = true;
};

// Function to close the LinkedAccountManualTestModal
const closeLinkedAccountManualTestModal = () => {
  showLinkedAccountManualTestModal.value = false;
};

const openMealSelectModal = (glucoseLevelId) => {
  mealSelectModalData.value = { glucoseLevelId };
  showMealSelectModal.value = true;
};

const closeMealSelectModal = () => {
  showMealSelectModal.value = false;
};

provide("openLinkedAccountTakeTestModal", openLinkedAccountTakeTestModal);
provide("closeLinkedAccountTakeTestModal", closeLinkedAccountTakeTestModal);
provide("openLinkedAccountManualTestModal", openLinkedAccountManualTestModal);
provide("closeLinkedAccountManualTestModal", closeLinkedAccountManualTestModal);

provide("openMealSelectModal", openMealSelectModal);
provide("closeMealSelectModal", closeMealSelectModal);

const updateSelectedDevice = (deviceName, deviceImage, testType) => {
  selectedDeviceImage.value = deviceImage;
  selectedTestType.value = testType;
};

// Blog post
const showBlogPostModal = ref(false);
const showEditBlogModal = ref(false);
const showDeleteBlogModal = ref(false);
const selectedPost = ref(null);

// Functions

// Appointment modal
const setAppointmentModalVal = (val) => {
  appointmentModalVal.value = val;
};

const setLinkedAppointmentModalVal = (val) => {
  LinkedAppointmentModal.value = val;
};

const openAppointmentModal = () => {
  showAppointmentModal.value = true;
};

const openLinkedAppointmentModal = () => {
  showLinkedAppointmentModal.value = true;
};

const closeAppointmentModal = () => {
  showAppointmentModal.value = false;
};

const closeLinkedAppointmentModal = () => {
  showLinkedAppointmentModal.value = false;
};

// Appointment modal prop values
provide("appointmentModalVal", appointmentModalVal);
provide("setAppointmentModalVal", setAppointmentModalVal);

provide("appointmentModal", showAppointmentModal);
provide("openAppointmentModal", openAppointmentModal);
provide("closeAppointmentModal", closeAppointmentModal);

provide("setLinkedAppointmentModal", setLinkedAppointmentModalVal);
provide("linkedAppointmentModal", showLinkedAppointmentModal);
provide("openLinkedAppointmentModal", openLinkedAppointmentModal);
provide("closeLinkedAppointmentModal", closeLinkedAppointmentModal);

// Report and statistics modal
const openReportAndStatisticsModal = () => {
  showReportAndStatisticsModal.value = true;
};

const closeReportAndStatisticsModal = () => {
  showReportAndStatisticsModal.value = false;
};

provide("reportAndStatisticsModal", showReportAndStatisticsModal);
provide("openReportAndStatisticsModal", openReportAndStatisticsModal);
provide("closeReportAndStatisticsModal", closeReportAndStatisticsModal);

// unlik patient modal

const openUnlinkPatientModal = (account) => {
  showUnlinkPatientModal.value = true;
  selectedAccount.value = account;
};

const closeUnlinkPatientModal = () => {
  showUnlinkPatientModal.value = false;
};

provide("unlinkPatientModal", showUnlinkPatientModal);
provide("openUnlinkPatientModal", openUnlinkPatientModal);
provide("closeUnlinkPatientModal", closeUnlinkPatientModal);

// Report note modal
const openReportNoteModal = () => {
  showReportNoteModal.value = true;
};

const closeReportNoteModal = () => {
  showReportNoteModal.value = false;
};

provide("reportNote", reportNote);
provide("reportNoteModal", showReportNoteModal);
provide("openReportNoteModal", openReportNoteModal);
provide("closeReportNoteModal", closeReportNoteModal);

// File upload successful modal
const openFileUploadSuccessfulModal = () => {
  showFileUploadSuccessfulModal.value = true;
};

const closeFileUploadSuccessfulModal = () => {
  showFileUploadSuccessfulModal.value = false;
};

provide("fileUploadSuccessfulModal", showReportAndStatisticsModal);
provide("openFileUploadSuccessfulModal", openFileUploadSuccessfulModal);
provide("closeFileUploadSuccessfulModal", closeFileUploadSuccessfulModal);

// Current Patient and device
provide("currentPatientAndDevice", currentPatientAndDevice);

// Test center modal
const openSelectTestTypeModal = () => {
  showSelectTestTypeModal.value = true;
};

const closeSelectTestTypeModal = () => {
  showSelectTestTypeModal.value = false;
};

// search patient
const openSearchPatientModal = () => {
  showSearchPatientModal.value = true;
};

const closeSearchPatientModal = () => {
  showSearchPatientModal.value = false;
};

const openTestModal = () => {
  showTestModal.value = true;
};

const closeTestModal = () => {
  showTestModal.value = false;
};

const openTestInstructionModal = ({
  selectedDeviceImage: deviceImage,
  selectedTestType: testType,
  isPatientDevice: patientDevice,
}) => {
  // Close other modals
  closeTakeTestModal();
  closeLinkedAccountTakeTestModal();

  // Update the reactive references
  selectedDeviceImage.value = deviceImage;
  selectedTestType.value = testType;
  isPatientDevice.value = patientDevice;

  // Open the TestInstructionModal
  showTestInstructionModal.value = true;
};

const closeTestInstructionModal = () => {
  showTestInstructionModal.value = false;
};

const manualTestModalData = ref({
  selectedTestType: "",
  patientId: "",
  existingData: null,
});
// Function to open the ManualTestModal with the selectedTestType
const openManualTestModal = (
  testType,
  patientId,
  existingData = null,
  editingData
) => {
  manualTestModalData.value = {
    selectedTestType: testType,
    patientId: patientId,
    existingData: existingData,
    editingData: editingData,
  };
  showManualTestModal.value = true;
};
// Function to close the ManualTestModal
const closeManualTestModal = () => {
  // Clear the manual test modal data
  manualTestModalData.value = {
    selectedTestType: "",
    patientId: "",
    existingData: null,
  };

  // Close the modal
  showManualTestModal.value = false;
};

const openEditManualTestModal = (patientName, patientId, editingData) => {
  editManualTestModalData.value = { patientName, patientId, editingData };
  showEditManualTestModal.value = true;
};

const closeEditManualTestModal = () => {
  showEditManualTestModal.value = false;
};

provide("selectedPatient", selectedPatient);
provide("showSelectTestTypeModal", showSelectTestTypeModal);
provide("openSelectTestTypeModal", openSelectTestTypeModal);
provide("closeSelectTestTypeModal", closeSelectTestTypeModal);
provide("searchPatientModal", showSearchPatientModal);
provide("openSearchPatientModal", openSearchPatientModal);
provide("closeSearchPatientModal", closeSearchPatientModal);
provide("testModal", showTestModal);
provide("openModal", openTestModal);
provide("closeModal", closeTestModal);
provide("openEditManualTestModal", openEditManualTestModal);
provide("closeEditManualTestModal", closeEditManualTestModal);

// Add new patient modal
const openAddNewPatientModal = () => {
  showAddNewPatientModal.value = true;
};

const closeAddNewPatientModal = () => {
  showAddNewPatientModal.value = false;
};

provide("addNewPatientModal", showAddNewPatientModal);
provide("openAddNewPatientModal", openAddNewPatientModal);
provide("closeAddNewPatientModal", closeAddNewPatientModal);

// Test steps modal
const openTestStepsModal = () => {
  showTestStepsModal.value = true;
};

const closeTestStepsModal = () => {
  // Reset all relevant values
  selectedDeviceView.value = null;
  selectedDeviceImage.value = null;
  selectedTestType.value = "";
  showDeviceSelection.value = false;
  showDeviceOptions.value = false;

  // Close the modal
  showTakeTestModal.value = false;
};

//new test modals
const openTakeTestModal = () => {
  showTakeTestModal.value = true;
};

const closeTakeTestModal = () => {
  // Clear all relevant state variables
  selectedDeviceView.value = null;
  selectedDeviceImage.value = null;
  selectedDeviceId.value = null;
  isPatientDevice.value = false;
  showDeviceSelection.value = false;
  showDeviceOptions.value = false;

  // Close the modal
  showTakeTestModal.value = false;
};

provide("testStepsModal", showTestStepsModal);
provide("openTestStepsModal", openTestStepsModal);
provide("closeTestStepsModal", closeTestStepsModal);

provide("openTakeTestModal", openTakeTestModal);
provide("closeTakeTestModal", closeTakeTestModal);
provide("openTestInstructionModal", openTestInstructionModal);
provide("closeTestInstructionModal", closeTestInstructionModal);
provide("selectedDeviceImage", selectedDeviceImage);
provide("selectedTestType", selectedTestType);
provide("updateSelectedDevice", updateSelectedDevice);
provide("isPatientDevice", isPatientDevice);

provide("openManualTestModal", openManualTestModal);
provide("closeManualTestModal", closeManualTestModal);

// Test successful modal
const openTestSuccessfulModal = () => {
  showTestSuccessfulModal.value = true;
};

const closeTestSuccessfulModal = () => {
  showTestSuccessfulModal.value = false;
};

provide("testSuccessfulModal", showTestSuccessfulModal);
provide("openTestSuccessfulModal", openTestSuccessfulModal);
provide("closeTestSuccessfulModal", closeTestSuccessfulModal);

// Blog post
// view blog post
const openViewBlogModal = (post) => {
  selectedPost.value = post;
  showBlogPostModal.value = true;
};

const closeViewBlogModal = () => {
  showBlogPostModal.value = false;
};

// edit blog post
const openEditBlogPostModal = (post) => {
  selectedPost.value = post;
  showEditBlogModal.value = true;
};

const closeEditBlogModal = () => {
  showEditBlogModal.value = false;
};

// open delete blog post
const openDeleteBlogPostModal = (post) => {
  selectedPost.value = post;
  showDeleteBlogModal.value = true;
};

const closeDeleteBlogModal = () => {
  showDeleteBlogModal.value = false;
};

provide("openBlogPostModal", openViewBlogModal);
provide("closeViewBlogModal", closeViewBlogModal);
provide("openEditBlogModal", openEditBlogPostModal);
provide("closeEditBlogModal", closeEditBlogModal);
provide("openDeleteBlogModal", openDeleteBlogPostModal);
provide("closeDeleteBlogModal", closeDeleteBlogModal);
</script>
