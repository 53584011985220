<template>
  <div class="w-full h-full flex flex-col">
    <SearchPatient
      :iconSrc="searchIcon"
      :containerClasses="'space-y-6 md:w-full lg:w-[70%] xl:w-[40%] mb-6'"
      :searchBarClasses="'flex flex-row items-center justify-between border rounded-md px-2 py-2.5'"
      :inputClasses="'bg-transparent w-full focus:outline-0 px-3 py-1 text-sm'"
      placeholder="Search for patient by username"
      @selectPatient="openAccountLinkingModal"
    />

    <div class="flex-grow flex flex-col">
      <div
        v-if="
          !isFetchingPatients && getPatientRequests?.connected?.length === 0
        "
        class="h-20 flex justify-center items-center"
      >
        <p>You have no linked accounts</p>
      </div>

      <div
        v-else-if="!isFetchingPatients && getPatientRequests"
        class="flex-grow flex flex-col"
      >
        <div
          v-if="getPatientRequests?.connected?.length > 0"
          class="border rounded-3xl px-4 py-8 flex-grow flex flex-col"
        >
          <div class="flex items-center justify-between mb-6">
            <div class="flex items-center gap-x-1.5">
              <p class="font-semibold text-lg md:text-xl">Linked Accounts</p>
              <span
                v-if="getPatientRequests?.connected"
                class="bg-ResolutionBlue text-white w-5 h-5 text-xs rounded-full flex justify-center items-center"
                >{{ getPatientRequests?.connected?.length }}</span
              >
            </div>
          </div>

          <div class="flex-grow overflow-auto custom-scrollbar">
            <table class="w-full">
              <thead>
                <tr>
                  <th
                    class="sticky top-0 bg-white min-w-[100px] py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Name
                  </th>
                  <th
                    class="sticky top-0 bg-white min-w-[100px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Relationship
                  </th>
                  <th
                    class="sticky top-0 bg-white min-w-[200px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Date
                  </th>
                  <th
                    class="sticky top-0 bg-white min-w-[100px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Time
                  </th>
                  <th
                    class="sticky top-0 bg-white min-w-[100px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="mb-4 font-light"
                  v-for="account in paginatedAccounts"
                  :key="account.id"
                >
                  <LinkedAccounts :account="account" />
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex items-center py-4">
            <button
              class="flex justify-center items-center w-[38px] h-[38px] bg-gray-100 border rounded-[4px] disabled:opacity-50"
              :disabled="currentPage === 1"
              @click="currentPage--"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>

            <div class="flex space-x-2 mx-2">
              <button
                v-for="page in visiblePages"
                :key="page"
                @click="currentPage = page"
                :class="[
                  'w-[38px] h-[38px] flex justify-center items-center border rounded-[4px]',
                  currentPage === page
                    ? 'bg-ResolutionBlue text-white'
                    : 'bg-gray-100 text-black',
                ]"
              >
                {{ page }}
              </button>
            </div>
            <button
              class="flex justify-center items-center w-[38px] h-[38px] bg-gray-100 border rounded-[4px] disabled:opacity-50"
              :disabled="currentPage === totalPages"
              @click="currentPage++"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>

            <span class="text-gray-500 ml-2">of {{ totalPages }} pages</span>
          </div>
        </div>
      </div>

      <div v-else class="h-20 flex justify-center items-center">
        <img :src="loader" alt="loader icon" class="animate-spin" />
      </div>
    </div>
  </div>

  <AccountLinkingModal
    v-if="showAccountLinkingModal"
    :result="selectedResult"
    @close="closeAccountLinkingModal"
  />
</template>

<script setup>
import { ref, provide, onMounted, computed } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import LinkedAccounts from "./LinkedAccounts.vue";
import AccountLinkingModal from "./AccountLinkingModal.vue";
import loader from "@/assets/icons/loading-01.svg";
import SearchPatient from "@/components/main/ui/SearchPatient.vue"
import searchIcon from "@/assets/icons/Search.svg"

const {
  "user/searchPatient": searchPatient,
  "accountConnection/fetchPatientRequests": fetchPatientRequests,
} = mapActions();

const { "accountConnection/getPatientRequests": getPatientRequests } =
  mapGetters();


const showAccountLinkingModal = ref(false);
const isFetchingPatients = ref(false);
const isLoading = ref(false);
const searching = ref(false);
const selectedResult = ref(null);
const connectedPatient = ref(null);
const currentPage = ref(1);
const accountsPerPage = ref(5);

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

// open account linking modal
const openAccountLinkingModal = (result) => {
  selectedResult.value = result;
  showAccountLinkingModal.value = true;
};

// close account linking modal
const closelinkAccountModal = () => {
  showAccountLinkingModal.value = false;
};

provide("closelinkAccountModal", closelinkAccountModal);


const getAllRequests = async () => {
  isLoading.value = true;
  try {
    const res = await fetchPatientRequests();
    connectedPatient.value = res;
  } catch (error) {
    console.log("err", error);
  } finally {
    isLoading.value = false;
  }
};

getAllRequests();

onMounted(async () => {
  isFetchingPatients.value = true;
  await fetchPatientRequests();
  isFetchingPatients.value = false;
});

const updateFullName = (event) => {
  relationship.value = event.target.value;
};



const closeAccountLinkingModal = () => {
  searchKeyword.value = "";
  showAccountLinkingModal.value = false;
};

const totalAccounts = computed(() => {
  console.log("Connected Accounts:", connectedPatient.value?.connected);
  return connectedPatient?.value?.connected?.length || 0;
});

const totalPages = computed(() => {
  console.log("Total Accounts:", totalAccounts.value);
  return Math.ceil(totalAccounts.value / accountsPerPage.value);
});

const paginatedAccounts = computed(() => {
  const start = (currentPage.value - 1) * accountsPerPage.value;
  return connectedPatient?.value?.connected?.slice(
    start,
    start + accountsPerPage.value
  );
});

const visiblePages = computed(() => {
  const maxVisible = 5;
  let startPage = Math.max(1, currentPage.value - Math.floor(maxVisible / 2));
  let endPage = Math.min(totalPages.value, startPage + maxVisible - 1);
  if (endPage - startPage + 1 < maxVisible && totalPages.value >= maxVisible) {
    startPage = Math.max(1, endPage - maxVisible + 1);
  }

  return Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );
});
</script>
