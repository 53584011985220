<template>
  <Transition name="fade">
    <div
      class="fixed top-0 left-0 z-[70] bg-[#A3A3A3]/20 backdrop-blur-sm w-full h-screen py-10 overflow-y-scroll no-scrollbar flex justify-center"
    >
      <form
        @submit.prevent="handleSchedule"
        class="self-start bg-white w-full max-w-[550px] p-6 md:p-12 rounded-[38px] flex flex-col gap-y-5 relative"
      >
        <div
          class="absolute rounded-[44px] left-0 top-0 w-full h-full"
          @click="closeDropdowns"
        ></div>

        <div class="flex justify-between items-center">
          <h3 class="text-GunMetal text-xl font-bold">
              Request Appointment For Linked Account
          </h3>
          <button type="button" class="self-start" @click="cancelAppointment">
            <span
              class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 befoafterre:top-0 after:-rotate-45 cursor-pointer"
            ></span>
          </button>
        </div>

        <div>
          <h4 class="text-DarkJungle text-xs font-semibold mb-3">
            Appointment Type
          </h4>

          <div class="bg-WhiteLilac w-fit p-1 relative">
            <button
              type="button"
              class="w-[126px] text-sm py-1"
              :class="
                currentTab === 'physical'
                  ? 'bg-ResolutionBlue text-white font-bold'
                  : 'text-PaleSky'
              "
              @click="currentTab = 'physical'"
            >
              Physical
            </button>
            <button
              type="button"
              class="w-[126px] text-sm py-1"
              :class="
                currentTab === 'online'
                  ? 'bg-ResolutionBlue text-white font-bold'
                  : 'text-PaleSky'
              "
              @click="currentTab = 'online'"
            >
              online
            </button>
          </div>
        </div>

        <div v-if="currentTab === 'physical'" class="flex flex-col gap-y-4">
          <div class="relative">
            <label for="patientName" class="text-BluishGrey text-sm"
              >Patient's Name</label
            >
            <input
              type="text"
              id="patientName"
              class="w-full border-b border-BlueChalk outline-none"
              v-model="patientName"
              disabled
            />
          </div>

          <div class="relative">
            <label for="country" class="text-BluishGrey text-sm">Country</label>
            <input
              type="text"
              id="country"
              class="w-full border-b border-BlueChalk outline-none"
              v-model="country"
              @focus="openCountriesDropdown"
            />
            <img
              src="../../../../assets/icons/down_caret.svg"
              alt="down caret icon"
              class="absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer"
              @click="openCountriesDropdown"
            />
            <LocationsDropdown
              v-show="showCountriesDropdown"
              :locations="countries"
              @setLocation="selectCountry"
            />
          </div>

          <div class="relative">
            <label for="state" class="text-BluishGrey text-sm">State</label>
            <input
              type="text"
              id="state"
              class="w-full border-b border-BlueChalk outline-none"
              v-model="state"
              @focus="openStatesDropdown"
            />
            <img
              src="../../../../assets/icons/down_caret.svg"
              alt="down caret icon"
              class="absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer"
              @click="openStatesDropdown"
            />
            <LocationsDropdown
              v-show="showStatesDropdown"
              :locations="states"
              @setLocation="selectState"
            />
          </div>

          <div class="relative">
            <label for="city" class="text-BluishGrey text-sm">City</label>
            <input
              type="text"
              id="city"
              class="w-full border-b border-BlueChalk outline-none"
              v-model="city"
              @focus="openCitiesDropdown"
            />
            <img
              src="../../../../assets/icons/down_caret.svg"
              alt="down caret icon"
              class="absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer"
              @click="openCitiesDropdown"
            />
            <LocationsDropdown
              v-show="showCitiesDropdown"
              :locations="cities"
              @setLocation="selectCity"
            />
          </div>

          <div class="relative">
            <label for="health-center" class="text-BluishGrey text-sm"
              >Health Center</label
            >
            <input
              type="text"
              id="health-center"
              class="w-full border-b border-BlueChalk outline-none"
              v-model="center"
              @focus="openCentersDropdown"
            />
            <img
              src="../../../../assets/icons/down_caret.svg"
              alt="down caret icon"
              class="absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer"
              @click="openCentersDropdown"
            />
            <LocationsDropdown
              v-show="showHealthCentersDropdown"
              :locations="centers"
              @setLocation="selectCenter"
            />
          </div>

          <div class="relative">
            <label for="appointmentReason" class="text-BluishGrey text-sm"
              >Reason for appointment</label
            >
            <input
              type="text"
              id="appointmentReason"
              class="w-full border-b border-BlueChalk outline-none"
              v-model="appointmentReason"
              @focus="showReasonsDropdown = true"
            />

            <ReasonsForAppointmentDropdown
              v-show="showReasonsDropdown"
              @selectReason="handleSelectReason"
            />
          </div>

          <div
            class="relative flex justify-between items-center text-BluishGrey text-sm"
          >
            <div class="flex flex-col gap-2">
              <label for="date">Date</label>
              <div class="min-w-[120px] relative">
                <input
                  type="date"
                  id="date"
                  class="custom-input w-full border-BlueChalk border-b outline-none relative"
                  :min="currentDate"
                  v-model="date"
                />
                <button
                  type="button"
                  class="size-6 absolute top-1/2 right-0 -translate-y-1/2 z-[1]"
                >
                  <img :src="calendarIcon" alt="calendar icon" />
                </button>
              </div>
            </div>

            <div>
              <div class="flex flex-col gap-2">
                <label for="time">Time</label>
                <select
                  name="time"
                  id="time"
                  class="outline-BlueChalk border-b border-BlueChalk"
                  v-model="time"
                >
                  <option value="">Select Time</option>
                  <option
                    v-for="(item, index) in availableTimes"
                    :key="index"
                    :value="item.value"
                    class="uppercase"
                  >
                    {{ item.time }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="h-full flex justify-center items-center">
          <p>Coming soon</p>
        </div>

        <div
          v-if="address && currentTab === 'physical'"
          class="bg-Magnolia py-3 pr-12 pl-4 rounded-[10px]"
        >
          <div class="flex gap-1.5">
            <div>
              <img
                src="../../../../assets/icons/location_pin.svg"
                alt="location pin icon"
              />
            </div>
            <div class="text-ResolutionBlue">
              <p class="text-xs mb-0.5">
                This health center is located at the following address:
              </p>
              <p class="text-sm font-bold">
                {{ address }}
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="currentTab === 'physical'"
          class="mt-3 flex justify-center items-center gap-2 flex-wrap relative"
          :class="currentTab === 'online' && 'mt-4'"
        >
          <button
            type="button"
            class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
            @click="cancelAppointment"
          >
            Cancel
          </button>
          <button
            v-if="currentTab === 'physical'"
            type="submit"
            class="text-sm font-semibold rounded-[44px] py-[13px] px-[52px]"
            :class="
              allSelected
                ? 'bg-ResolutionBlue text-white'
                : 'bg-Geyser text-MistBlue'
            "
          >
            {{
              appointmentType === "default"
                ? processing
                  ? "Booking..."
                  : "Book"
                : processing
                ? "Updating..."
                : "Update"
            }}
          </button>
          <router-link
            to="/patient/online-appointment"
            v-if="currentTab === 'online'"
            class="cursor-pointer w-fit text-sm font-semibold rounded-[44px] py-[13px] px-[52px] bg-ResolutionBlue text-white"
            @click="openCalendly"
          >
            Schedule
          </router-link>
        </div>
      </form>
    </div>
  </Transition>
</template>

<script>
import { ref, inject, computed, watch } from "vue";
import { toast } from "vue3-toastify";
import { mapActions } from "@/hooks/mapStore";
import { extractTime } from "@/utils/timeFormatter";
import LocationsDropdown from "../dropdowns/LocationsDropdown.vue";
import ReasonsForAppointmentDropdown from "./ReasonsForAppointmentDropdown.vue";
import calendarIcon from "@/assets/icons/Calendar.svg";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';

export default {
  name: "AppointmentModal",
  components: {
    LocationsDropdown,
    ReasonsForAppointmentDropdown,
  },
  props: {
    appointmentValues: {
      type: Object,
    },
  },
  setup(props) {
      const store = useStore();
  const route = useRoute();

    const closeLinkedAppointmentModal = inject("closeLinkedAppointmentModal");
    const setAppointmentModalVal = inject("setAppointmentModalVal");

    const showCountriesDropdown = ref(false);
    const showStatesDropdown = ref(false);
    const showCitiesDropdown = ref(false);
    const showHealthCentersDropdown = ref(false);
    const showReasonsDropdown = ref(false);
    const currentTab = ref("physical");
    const posts = ref([]);
    const allData = ref([]);
    const countries = ref([]);
    const states = ref([]);
    const cities = ref([]);
    const centers = ref([]);
    const processing = ref(false);
        const patientName = ref('');

    const availableTimes = [
      {
        time: "9am",
        value: "9:00:00",
      },
      {
        time: "10am",
        value: "10:00:00",
      },
      {
        time: "11am",
        value: "11:00:00",
      },
      {
        time: "12pm",
        value: "12:00:00",
      },
      {
        time: "1pm",
        value: "13:00:00",
      },
      {
        time: "2pm",
        value: "14:00:00",
      },
      {
        time: "3pm",
        value: "15:00:00",
      },
      {
        time: "4pm",
        value: "16:00:00",
      },
      {
        time: "5pm",
        value: "17:00:00",
      },
    ];

    const {
      "appointment/bookLinkedAppointment": bookLinkedAppointment,
      "appointment/editAppointment": editAppointment,
      "appointment/fetchPatientAppointments": fetchPatientAppointments,
      "test/fetchAllHealthCenters": fetchAllHealthCenters,
          "user/fetchPatientProfile": fetchPatientProfile,
    } = mapActions();

    const setValues = (data) => {
      allData.value = data;
      data.forEach((post) => {
        countries.value.push(post.country);
        states.value.push(post.state);
        cities.value.push(post.city);
        centers.value.push(post.name);
      });
      countries.value = [...new Set(countries.value)];
      states.value = [...new Set(states.value)];
      cities.value = [...new Set(cities.value)];
      centers.value = [...new Set(centers.value)];
    };

    const country = ref("");
    const state = ref("");
    const city = ref("");
    const center = ref("");
    const centerId = ref(null);
    const appointmentReason = ref("");
    const address = ref("");
    const date = ref("");
    const time = ref("");
    const appointmentType = ref("default");

    watch(props, () => {
      country.value = props.appointmentValues.country;
      state.value = props.appointmentValues.state;
      city.value = props.appointmentValues.city;
      center.value = props.appointmentValues.center;
      appointmentReason.value = props.appointmentValues.appointmentReason;
      address.value = props.appointmentValues.address;
      date.value = props.appointmentValues.date;
      time.value = extractTime(props.appointmentValues.time);
      appointmentType.value = props.appointmentValues.type;
    });

    const currentDate = ref(new Date().toISOString().slice(0, 10));

    const fetchHealthCenters = async () => {
      try {
        const res = await fetchAllHealthCenters();
        posts.value = res.posts;
        setValues(res.posts);
      } catch (error) {
        console.log("Error fetching all health centers", error);
      }
    };

    fetchHealthCenters();

    const loadPatient = async (id) => {
    try {
      const res = await store.dispatch('accountConnection/getLinkedAccountVitals', { id });
      patientName.value = res.patient_details.full_name;
    } catch (error) {
      console.error('Error loading patient details:', error);
    }
  };

    watch(() => route.params.id, (newId) => {
      console.log('Loading patient with ID:', newId);
    loadPatient(newId);
  });

  // Initial load
  loadPatient(route.params.id);

    const allSelected = computed(() => {
      return !!(
        country.value &&
        state.value &&
        city.value &&
        center.value &&
        appointmentReason.value &&
        date.value &&
        time.value
      );
    });

    const selectCountry = (value) => {
      country.value = value;
      showCountriesDropdown.value = false;
      const c = allData.value.filter(
        (datum) => datum.country === country.value
      );
      states.value = [...new Set(c.map((item) => item.state))];
    };

    const selectState = (value) => {
      state.value = value;
      showStatesDropdown.value = false;
      const st = allData.value.filter((datum) => datum.state === state.value);
      cities.value = [...new Set(st.map((item) => item.city))];
    };

    const selectCity = (value) => {
      city.value = value;
      showCitiesDropdown.value = false;
      const cty = allData.value.filter((datum) => datum.city === city.value);
      centers.value = [
        ...new Set(cty.map((item) => ({ id: item.id, name: item.name }))),
      ];
    };

    const selectCenter = (value) => {
      center.value = value.name;
      centerId.value = value.id;
      showHealthCentersDropdown.value = false;
      const location = allData.value.find((item) => item.name === value.name);
      if (location) {
        address.value = location.address;
      }
    };

    const openCountriesDropdown = () => {
      showCountriesDropdown.value = true;
      showStatesDropdown.value = false;
      showCitiesDropdown.value = false;
      showHealthCentersDropdown.value = false;
    };

    const openStatesDropdown = () => {
      showStatesDropdown.value = true;
      showCountriesDropdown.value = false;
      showCitiesDropdown.value = false;
      showHealthCentersDropdown.value = false;
    };

    const openCitiesDropdown = () => {
      showCitiesDropdown.value = true;
      showStatesDropdown.value = false;
      showCountriesDropdown.value = false;
      showHealthCentersDropdown.value = false;
    };

    const openCentersDropdown = () => {
      showHealthCentersDropdown.value = true;
      showCitiesDropdown.value = false;
      showStatesDropdown.value = false;
      showCountriesDropdown.value = false;
    };

    const closeDropdowns = () => {
      showCountriesDropdown.value = false;
      showStatesDropdown.value = false;
      showCitiesDropdown.value = false;
      showHealthCentersDropdown.value = false;
      showReasonsDropdown.value = false;
    };

    const resetInputFields = () => {
      country.value = "";
      state.value = "";
      city.value = "";
      center.value = "";
      appointmentReason.value = "";
      currentTab.value = "physical";
      address.value = "";
      date.value = "";
      time.value = "";
    };

    const cancelAppointment = () => {
      resetInputFields();
      setValues(posts.value);
      setAppointmentModalVal({
        type: "default",
        country: "",
        state: "",
        city: "",
        center: "",
        appointmentReason: "",
        address: "",
        date: "",
        time: "",
        appointmentId: "",
      });
      closeDropdowns();
      closeLinkedAppointmentModal();
    };

    const handleSchedule = async () => {
      const data = {
        type: currentTab.value,
        reason_for_appointment: appointmentReason.value,
        date: date.value,
        time: time.value,
      };

      const payload = {
        patient_id: route.params.id,
        health_centre_id: centerId.value,
        data,
      };

      console.log(payload);
      console.log(centerId.value)

      if (allSelected.value) {
        processing.value = true;
        try {
          if (props.appointmentValues.type == "default") {
            await bookLinkedAppointment(payload);
            toast.success("Appointment booked successfully", {
              autoClose: 2000,
            });
          } else if (props.appointmentValues.type == "edit") {
            await editAppointment({
              appointmentId: props.appointmentValues.appointmentId,
              data,
            });
            toast.success("Appointment successfully edited", {
              autoClose: 3000,
            });
          }
          cancelAppointment();
          fetchPatientAppointments({ status: "all", page: 1 });
        } catch (error) {
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message, {
              autoClose: 2000,
            });
          } else if (error?.response?.data?.error) {
            toast.error(error.response.data.error, {
              autoClose: 2000,
            });
          } else if (error?.message) {
            toast.error(error.message, {
              autoClose: 2000,
            });
          } else if (props.appointmentValues.type === "default") {
            toast.error("Error booking appointment", {
              autoClose: 2000,
            });
          } else {
            toast.error("Error updating appointment", {
              autoClose: 2000,
            });
          }
        } finally {
          processing.value = false;
        }
      } else {
        toast.info("Fill in all fields", {
          autoClose: 2000,
        });
      }
    };

    const openCalendly = () => {
      closeLinkedAppointmentModal();
      // Replace 'https://calendly.com/simpla-worfklows-hannahm/30min' with your actual Calendly link
      const calendlyLink = "https://calendly.com/turbomedics/30min";

      // Check if Calendly is already initialized
      if (typeof Calendly !== "undefined") {
        Calendly.initPopupWidget({ url: calendlyLink });
      } else {
        // Load Calendly script if not already loaded
        const script = document.createElement("script");
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        script.onload = () => {
          Calendly.initPopupWidget({ url: calendlyLink });
        };
        document.head.appendChild(script);
      }
    };

    const handleSelectReason = (value) => {
      appointmentReason.value = value;
      closeDropdowns();
    };

    // const openCalendly = () => {
    //   // Replace 'YOUR_CAL_ENDLY_LINK' with your actual Calendly link
    //   const calendlyLink = "https://calendly.com/turbomedics/30min";

    //   // Open Calendly popup
    //   Calendly.initPopupWidget({ url: calendlyLink });
    // };
    // const openCalendly = () => {
    //   // Replace 'YOUR_CAL_ENDLY_LINK' with your actual Calendly link
    //   const calendlyLink = "YOUR_CAL_ENDLY_LINK";

    //   // Check if Calendly is already initialized
    //   if (typeof Calendly !== "undefined") {
    //     Calendly.showPopupWidget({ url: calendlyLink });
    //   } else {
    //     // Load Calendly script if not already loaded
    //     const script = document.createElement("script");
    //     script.src = "https://assets.calendly.com/assets/external/widget.js";
    //     script.async = true;
    //     script.onload = () => {
    //       Calendly.showPopupWidget({ url: calendlyLink });
    //     };
    //     document.head.appendChild(script);
    //   }
    // };

    // onMounted(() => {
    //   let Script = document.createElement("script");
    //   Script.setAttribute(
    //     "src",
    //     // "https://assets.calendly.com/assets/external/widget.js"
    //     "https://assets.calendly.com/assets/external/widget.js"
    //   );
    //   document.head.appendChild(Script);
    // });

    return {
      calendarIcon,
      closeLinkedAppointmentModal,
      country,
      countries,
      selectCountry,
      showCountriesDropdown,
      openCountriesDropdown,
      state,
      states,
      selectState,
      showStatesDropdown,
      openStatesDropdown,
      city,
      cities,
      selectCity,
      showCitiesDropdown,
      openCitiesDropdown,
      center,
      centers,
      selectCenter,
      showHealthCentersDropdown,
      openCentersDropdown,
      address,
      appointmentReason,
      currentTab,
      cancelAppointment,
      closeDropdowns,
      allSelected,
      handleSchedule,
      openCalendly,
      date,
      time,
      availableTimes,
      currentDate,
      processing,
      appointmentType,
      showReasonsDropdown,
      handleSelectReason,
      patientName,
    };
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, scale 0.3s ease;
  scale: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  scale: 1.2;
}
</style>
