<template>
  <ModalWrapper>
    <div
      class="max-w-[724px] w-full flex flex-col gap-6 p-6 md:p-12 rounded-[48px] bg-white relative"
    >
      <div class="flex justify-between items-center">
        <h3 class="text-ResolutionBlue text-xl font-bold">
          Select when you did this test
        </h3>
        <div class="cursor-pointer" @click="closeMealSelectModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 justify-center gap-4">
        <div
          v-for="option in options"
          :key="option.id"
          @click="selectOption(option)"
          :class="[
            'flex flex-col items-center p-4 md:px-4 md:py-6 border rounded-lg cursor-pointer',
            { selected: option.id === selectedOption },
          ]"
        >
          <img :src="option.icon" alt="" class="w-12 h-12" />
          <span class="mt-2 text-sm">{{ option.label }}</span>
        </div>
      </div>
      <div class="max-w-[296px] w-full flex gap-3 items-center self-center">
        <button
          @click="closeMealSelectModal"
          class="w-full py-2 px-4 border border-ResolutionBlue text-ResolutionBlue rounded-full"
        >
          Cancel
        </button>
        <button
          @click="confirmSelection"
          :disabled="isDisabled"
          class="w-full py-2 px-4 bg-ResolutionBlue text-white rounded-full"
        >
          <span v-if="isLoading">
            <LoadingSpinner />
          </span>
          <span v-else> Done </span>
        </button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import ModalWrapper from "./ModalWrapper.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import Fork from "../../../../../assets/icons/fork-icon.svg";
import Bowl from "../../../../../assets/icons/bowl-icon.svg";
import Fast from "../../../../../assets/icons/fast-icon.svg";

const props = defineProps({
  glucoseLevelId: String,
});
console.log(`MealSelectModal received glucoseLevelId: ${props.glucoseLevelId}`);

const store = useStore();
const isLoading = ref(false);
const options = ref([
  { id: "beforeMeal", label: "Before Meal", icon: Fork },
  { id: "afterMeal", label: "After Meal", icon: Bowl },
  // { id: "fasting", label: "Fasting", icon: Fast },
]);

const selectedOption = ref(options.value[0].id);

const selectOption = (option) => {
  selectedOption.value = option.id;
};

const openTestSuccessfulModal = inject("openTestSuccessfulModal");
const closeMealSelectModal = inject("closeMealSelectModal");

const isDisabled = computed(() => {
  return !selectedOption.value || isLoading.value;
});

const confirmSelection = async () => {
  if (isDisabled.value) return;

  isLoading.value = true;
  const beforeMeal =
    selectedOption.value === "beforeMeal" || selectedOption.value === "fasting";

  try {
    await store.dispatch("test/attachMealSelect", {
      glucoseLevelId: props.glucoseLevelId,
      beforeMeal,
    });
    openTestSuccessfulModal();
  } catch (error) {
    toast.error("Error:", error);
    // Handle error, e.g., show an error notification
  } finally {
    isLoading.value = false;
    closeMealSelectModal();
  }
};
</script>

<style scoped>
.selected {
  border-color: #011d7e;
  background-color: #f2f4f9;
}
</style>
