import axios from "@/api";


const state = {
  appointments: {
    all: [],
    pending: [],
    completed: [],
    expired: [],
    cancelled: [],
  },
  totalResults: 0,
  perPage: 10,
  totalPages: 1,
};

const getters = {
  getAppointmentsByStatus: (state) => (status) => {
    return state.appointments[status] || [];
  },
  totalPages: (state) => state.totalPages,
};

const mutations = {
  setAppointments(state, { status, appointments }) {
    state.appointments[status] = appointments;
  },
  setTotalResults(state, totalResults) {
    state.totalResults = totalResults;
  },
  setTotalPages(state, totalPages) {
    state.totalPages = totalPages;
  },
};

const actions = {
  async fetchPatientAppointments({ commit }, { status = "all", page = 1 }) {
    try {
      const response = await axios.get(`/data/appointments`, {
        params: {
          status: status !== "all" ? status : undefined,
          page,
          per_page: state.perPage
        },
      });

      console.log(response);

      const { appointments, total_results, per_page } = response.data;
      console.log(appointments);
      console.log(total_results);

      commit("setAppointments", { status, appointments });
      commit("setTotalResults", total_results);
      commit("setTotalPages", Math.ceil(total_results / per_page));
    } catch (error) {
      throw error;
    }
  },


  async bookAppointment({ dispatch }, payload) {
    try {
      await axios.post(
        `/data/appointment/test_centre/${payload.id}`,
        payload.data
      );
      // After successful booking, fetch all appointments to update the UI
      await dispatch("fetchPatientAppointments", { status: "all", page: 1 });
    } catch (error) {
      throw error;
    }
  },
  async bookLinkedAppointment({ dispatch }, payload) {
    try {
      await axios.post(
        `/data/patient/connected/appointment/${payload.patient_id}/${payload.health_centre_id}`,
        payload.data
      );
      // After successful booking, fetch all appointments to update the UI
      await dispatch("fetchPatientAppointments", { status: "all", page: 1 });
    } catch (error) {
      throw error;
    }
  },

  async getAvailableTimes({ commit }, { healthCentreId, date }) {
    console.log("Fetching available times for health center:", healthCentreId, "on date:", date);
    try {
      const response = await axios.get(
        `/data/appointment/appointment_for_day/${healthCentreId}`, {
        params: { date }
      }
      );
      console.log("Available times fetched successfully:", response.data);
      return response.data.available_slots;
    } catch (error) {
      console.error("Error fetching available times:", error);
      throw error;
    }
  },


  async editAppointment({ dispatch }, payload) {
    try {
      await axios.put(
        `data/appointment/${payload.appointmentId}`,
        payload.data
      );

      // After successful editing, fetch all appointments to update the UI
      await dispatch("fetchPatientAppointments", { status: "all", page: 1 });
    } catch (error) {
      throw error;
    }
  },

  async cancelAppointment({ dispatch }, payload) {
    try {
      const response = await axios.put(
        `data/appointment/delete/${payload.id}`,
        {
          reason_for_cancellation: payload.reason,
        }
      );
      // After successful cancellation, fetch all appointments to update the UI
      await dispatch("fetchPatientAppointments", { status: "all", page: 1 });
      return response.data.message;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
